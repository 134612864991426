<template>
  <!-- :visible.sync="visible" -->
  <el-drawer
    size="400"
    :title="`Cliente ${drawerdata.customer}`"
    :visible="visible"
    :direction="direction"
    :before-close="beforeClose"
    close-on-press-escape
    class="cargo-drawer"
  >
    <el-card class="cargo-details-box-card">
      <div slot="header" class="clearfix">
        <span>Cliente</span>
      </div>

      <cargo-tags :customer="drawerdata" direction="row" />
      <br />

      <div class="lineDetails">
        <b>Cliente:</b> {{ drawerdata.customer }}
        <div class="status-details">
          <b>Endereço:</b> {{ drawerdata.address1 }}
          {{ drawerdata.address2 ? ` - ${drawerdata.address2}` : '' }}
          {{ ` - ${drawerdata.neighborhood} - ${drawerdata.city}` }}
          <br />
          <b>CEP:</b> {{ drawerdata.postal_code }}
          <br />
          <b>Telefones:</b> {{ drawerdata.phone }}
        </div>
      </div>

      <br />
      <div class="lineDetails">
        <b>Status do cliente: </b>{{ drawerdata.statusLabel }}
        <div
          v-if="
            drawerdata.status === 'canceled' ||
            drawerdata.status === 'reavailable'
          "
          class="status-details-red"
        >
          <b>Motivo: </b>
          {{ cancellationReasonsDesc(drawerdata.canceled_reason) }}
          <br />
          <b>Observações adicionais: </b>
          {{ drawerdata.canceled_notice }}
          <br />
          <b>Horário do cancelamento: </b>
          {{ getDate(drawerdata.canceled_at.seconds) }}

          <div v-if="drawerdata.status === 'reavailable'">
            <b>Horário do reinicio: </b>
            {{ getDate(drawerdata.reavailable_at.seconds) }}

            <span v-if="drawerdata.delivered_at">
              <br />
              <b>Horário da entrega: </b>
              {{ getDate(drawerdata.delivered_at.seconds) }}
            </span>

            <span v-else>
              <br />
              Reentrega ainda não realizada
            </span>
          </div>
        </div>
      </div>

      <br />
      <div class="lineDetails">
        <div v-if="drawerdata.checked_fin">
          Financeiro conferido em
          {{
            $moment
              .unix(drawerdata.checked_fin_at.seconds)
              .format('DD/MM/YYYY HH:mm')
          }}
          por {{ drawerdata.checked_fin_by.split(' ')[0] }}
        </div>
        <div v-if="drawerdata.checked_log">
          Logística conferido em
          {{
            $moment
              .unix(drawerdata.checked_log_at.seconds)
              .format('DD/MM/YYYY HH:mm')
          }}
          por {{ drawerdata.checked_log_by.split(' ')[0] }}
        </div>
      </div>

      <br />
      <div class="lineDetails">
        <div v-if="drawerdata.comments">
          <div
            class="commentline"
            v-for="(comment, index) in drawerdata.comments"
            :key="`comment_${drawerdata.customer}_${index}`"
          >
            <b>
              {{
                $moment
                  .unix(comment.comment_at.seconds)
                  .format('DD/MM/YYYY HH:mm')
              }}
              por {{ comment.comment_by.split(' ')[0] }}
            </b>
            <br />
            {{ comment.comment }}
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="cargo-details-box-card">
      <div slot="header" class="clearfix">
        <span>Detalhes</span>
        <el-button
          type="text"
          style="float: right; padding: 3px 0;"
          v-if="drawerdata.hasOwnProperty('delivering_geo')"
          @click="showMapClick(drawerdata.deliverGeo)"
        >
          Mapa
        </el-button>
      </div>
      <el-steps
        :active="getActive(drawerdata)"
        align-center
        class="step-drawer"
      >
        <el-step
          title="Inicio da Carga"
          icon="el-icon-star-off"
          :description="drawerdata.cargoStartedAt"
        ></el-step>
        <el-step
          title="Renicio da entrega"
          icon="el-icon-truck"
          :description="getTime(drawerdata.reavailable_at.seconds)"
          v-if="drawerdata.status === 'reavailable'"
        ></el-step>
        <el-step
          title="Inicio da Entrega"
          icon="el-icon-truck"
          :description="drawerdata.deliveringAt"
        ></el-step>
        <el-step
          title="Checkin"
          icon="el-icon-location-outline"
          :description="drawerdata.deliveringCheckinAt"
        ></el-step>
        <el-step
          title="Checkout"
          icon="el-icon-location"
          :description="drawerdata.deliveredAt"
        ></el-step>
        <el-step
          title="Fim da Carga"
          icon="el-icon-circle-check"
          :description="drawerdata.cargoFinishedAt"
        ></el-step>
      </el-steps>

      <br />
      <div class="lineDetails">
        <b>Duração:</b> {{ drawerdata.deliveryDuration }}<br />
        <b>Km Percorrido:</b> {{ drawerdata.traveledKm }}<br />
        <b>Total de Notas:</b> {{ drawerdata.invoicesQuantity }}<br />
        <b>Total de Canceladas:</b> {{ drawerdata.invoicesCanceled }}<br />
        <b>Total de Parciais:</b> {{ drawerdata.invoicesPartial }}<br />
        <b>Peso:</b> {{ drawerdata.weight }}<br />
        <b>Valor:</b> {{ drawerdata.invoicesTotal }}<br />
        <b>Recebedor:</b> {{ drawerdata.delivered_who }}<br />
        <b>Documento:</b> {{ drawerdata.delivered_doc }}<br />
        <b>Informações do motorista:</b> {{ drawerdata.delivered_info }}
      </div>
      <p>
        <b>Observações de entrega:</b><br />
        {{ drawerdata.order_observations }}
      </p>
      <p>
        <b>Rota:</b> {{ drawerdata.route }}<br />
        <b>Zona:</b> {{ drawerdata.zone }}<br />
        <b>Setor:</b> {{ drawerdata.sector }}
      </p>
    </el-card>

    <el-card
      class="cargo-details-box-card"
      v-for="invoice in invoicesProcessed(drawerdata.invoices)"
      :key="invoice.invoice"
    >
      <div slot="header" class="clearfix">
        <div>
          <span>Nota fiscal {{ invoice.invoice }}</span>
          <el-button
            style="float: right; padding: 3px 2px; margin: 0px 6px; border: 1px solid #409EFF;"
            type="text"
            @click="kmClick({ row: invoice, field: 'receipt_photo_url' })"
            v-if="
              invoice.hasOwnProperty('receipt_photo_url') &&
              invoice.receipt_photo
            "
          >
            Comprovante de entrega
          </el-button>
          <el-button
            style="float: right; padding: 3px 2px; margin: 0px 6px; border: 1px solid #409EFF;"
            type="text"
            @click="makeDocumentAvailable(invoice)"
            v-if="invoice.content !== 'delivered'"
          >
            Nova entrega
          </el-button>
        </div>
      </div>

      <div class="lineDetails">
        <b>Nota Fiscal:</b> {{ invoice.invoice }}<br />
        <b>Valor Total:</b> {{ invoice.total }}<br />
        <b>Valor Recebido:</b> {{ invoice.amountreceivable }}<br />
        <b>Peso:</b> {{ invoice.weight }}<br />
        <b>Volumes:</b> {{ invoice.volumes }}
      </div>

      <br />
      <div class="lineDetails">
        <b>Status:</b> {{ invoice.statusLabel }}
        <el-button
          type="text"
          class="mini-text"
          @click="kmClick({ row: invoice, field: 'safeguard_photo_url' })"
          v-if="invoice.safeguard_photo_url"
          >Comprovante parcial</el-button
        >
        <div v-if="invoice.status === 'canceled'" class="status-details">
          <b>Motivo do cancelamento:</b> {{ invoice.cancel_desc }} ({{
            invoice.cancel_reason
          }})
          <br />
          <b>Observações do motorista:</b> {{ invoice.cancel_notice }}
        </div>
      </div>

      <p class="titulo"><b>Pagamento:</b></p>
      <el-table :data="invoice.payment" size="mini">
        <el-table-column
          width="120"
          property="ammountFormated"
          label="Produto"
        />
        <el-table-column property="nameFormated" label="Tipo" />
        <el-table-column property="nsuNumber" label="NSU" />
      </el-table>

      <div v-if="invoice.paymentEdited">
        <p class="titulo"><b>Pagamento alterado para:</b></p>
        <el-table :data="invoice.paymentEdited" size="mini">
          <el-table-column
            width="120"
            property="ammountFormated"
            label="Produto"
          />
          <el-table-column property="nameFormated" label="Tipo" />
          <el-table-column property="nsu" label="NSU" />
        </el-table>
      </div>

      <p class="titulo"><b>Itens da Nota</b></p>
      <el-table :data="invoice.items" size="mini">
        <el-table-column width="400" label="Produto">
          <template slot-scope="scope">
            <i
              class="el-icon-warning"
              v-if="scope.row.partial"
              style="color: red;"
            />
            {{ scope.row.product }}
            <br />
            <div
              class="mini-text"
              style="margin-left: 20px; color: red;"
              v-if="scope.row.partial"
            >
              {{ scope.row.partial_desc }} (Quantidade:
              {{ scope.row.partial_quantity }})
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          property="quantity"
          label="Quantidade"
        ></el-table-column>
        <el-table-column width="50" property="um" label="UN"></el-table-column>
      </el-table>
    </el-card>
  </el-drawer>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import cargoTags from './cargosTags'

export default {
  name: 'cargos-drawer',
  components: {
    cargoTags,
  },
  props: {
    drawerdata: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '400',
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    direction: {
      type: String,
      required: false,
      default: 'rtl',
    },
  },
  data() {
    return {
      drvisible: false,
      branch: '',
    }
  },
  computed: {
    ...mapState('Cargos', ['deliveryLabels']),
    ...mapGetters('User', [
      'cancellationReasonsPartialDesc',
      'cancellationReasonsDesc',
    ]),
  },
  methods: {
    ...mapMutations('Shared', ['setInfoMessage']),
    getActive(d) {
      this.branch = this._.get(d, '_cargo.branch_id', false)
      if (this._.get(d, '_cargo.finished_at.seconds', false)) return 5
      if (this._.get(d, 'delivered_at.seconds', false)) return 4
      if (this._.get(d, 'delivering_checkin_at.seconds', false)) return 3
      if (this._.get(d, 'delivering_at.seconds', false)) return 2
      if (this._.get(d, '_cargo.started_at.seconds', false)) return 1
      return 0
    },
    makeDocumentAvailable(invoice) {
      this.$confirm(
        `Deseja realmente redisponibilizar o documento ${
          invoice.invoice || invoice.order_number
        } para uma nova montagem de carga?`,
        'Aviso',
        {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            let {id} = invoice;
            if (!id) {
              id = `${this.branch}${invoice.invoice.replace(/\s/g, '')}`;
            }
            await this.$elastic.updateInvoiceStatus(id, 'A')
            this.$message({
              message: 'Documento redisponibilizado com sucesso.',
              type: 'success'
            });
          } catch (error) {
            console.error(error);
            this.$message.error('Ocorreu um erro ao redisponilizar o documento.');
          }
        })
        .catch(() => {})
    },
    invoicesProcessed(invoice) {
      return invoice
    },
    getDate(seconds) {
      return this.$moment.unix(seconds).format('DD/MM/YYYY HH:mm')
    },
    getTime(seconds) {
      return this.$moment.unix(seconds).format('HH:mm')
    },
    beforeClose() {
      this.$emit('update:drawerdata', {})
      this.$emit('update:visible', false)
    },
    showMapClick(points) {
      this.$emit('show-map', points)
    },
    kmEditClick(row, field) {
      this.$emit('km-edit-click', { row, field })
    },
    kmClick(d) {
      this.$emit('km-click', d)
    },
  },
  mounted() {},
}
</script>

<style>
.step-drawer .el-step__title {
  line-height: 18px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
