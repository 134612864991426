import { db } from '@/config/firebase'
import dbFilter from '@/helpers/firestoreQueryFilter'

export default {
  async getDriverHelpers({ commit, rootState }) {
    const companyId = rootState.User.profile.company_id

    let userRef = db
      .collection('companies')
      .doc(companyId)
      .collection('users')
      .orderBy('name')
      .where('roles', 'array-contains', 'driverhelper')

    let driverhelpers = await dbFilter.query(userRef)
    commit('changeDriverHelpers', driverhelpers)
    return driverhelpers
  },
}


