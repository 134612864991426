const defaultCargosFiltersState = () => {
  return {
    drivers: [],
    trucks: [],
    branches: [],
    checkers: [],
    status: [],
    states: [],
    neighborhoods: [],
    cities: [],
    routes: [],
    sectors: [],
    zones: [],
    datepicker: [],
    cargostart: '',
    cargoend: '',
  }
}

const getDefaultCargosState = () => {
  return {
    allCargos: [],
    statusLabels: [
      {
        value: 'Disponível',
        content: 'A',
        showOnChange: true,
        description: 'Carga disponível para iniciar entrega',
      },
      {
        value: 'Em entrega',
        content: 'D',
        showOnChange: false,
        description: 'Carga em andamento',
      },
      {
        value: 'Transferida',
        content: 'T',
        showOnChange: false,
        description: 'Carga transferida',
      },
      {
        value: 'Finalizada',
        content: 'F',
        showOnChange: false,
        description: 'Carga finalizada com sucesso',
      },
      {
        value: 'Bloqueada',
        content: 'B',
        showOnChange: true,
        description: 'Carga bloqueada',
      },
      {
        value: 'Erro',
        content: 'E',
        showOnChange: true,
        description: 'Carga com erro de processamento',
      },
      {
        value: 'Cancelada',
        content: 'C',
        showOnChange: true,
        description: 'Carga cancelada',
      },
      {
        value: 'Conferido',
        content: 'V',
        showOnChange: false,
        description: 'Carga conferida pelo financeiro e logistica',
      },
      {
        value: 'Conferindo',
        content: 'U',
        showOnChange: false,
        description: 'Carga com conferência em andamento',
      },
    ],
    // Status abaixo podem ser utilizados tanto no customer quanto no invoice
    deliveryLabels: [
      {
        value: 'Entregue',
        content: 'delivered',
        showOnChange: true,
        description: 'Entrega realizada com sucesso',
        color: 'success',
        logisticCheck: false,
      },
      {
        value: 'Cancelado',
        content: 'canceled',
        showOnChange: true,
        description: 'Cliente ou documento cancelado',
        color: 'danger',
        logisticCheck: true,
      },
      {
        value: 'Reentrega',
        content: 'reavailable',
        showOnChange: true,
        description: 'Cliente em reentrega',
        color: 'warning',
        logisticCheck: false,
      },
      {
        value: 'Reentrega parcial',
        content: 'reavailable_with_partial',
        showOnChange: true,
        description: 'Cliente com reentrega e reentrega parcial',
        color: 'warning',
        logisticCheck: true,
      },
      {
        value: 'Entrega parcial',
        content: 'with_partial', // status do invoice, status do customer = delivered, use combinações, olhar em cargoDetails.vue
        showOnChange: false,
        description: 'Contém nota com entrega parcial',
        color: 'warning',
        logisticCheck: true,
      },
      {
        value: 'Entrega cancelada',
        content: 'with_one_invoice_canceled', // Status do customer = delivered, app não grava esse status, usa combinações, olhar em cargoDetails.vue
        showOnChange: false,
        description: 'Cliente onde pelo menos uma nota foi cancelada',
        color: 'danger',
        logisticCheck: true,
      },
      {
        value: 'Entregando',
        content: 'delivering',
        showOnChange: false,
        description: 'Nota em rota de entrega',
        color: 'primary',
        logisticCheck: false,
      },
      {
        value: 'Checkin',
        content: 'delivering_checkin',
        showOnChange: false,
        description: 'Nota no processo de finalização de entrega',
        color: 'primary',
        logisticCheck: false,
      },
      {
        value: 'Não Iniciado',
        content: 'not_started', // Esse valor não esta sendo gravado nas notas ainda, quando não iniciado, campo status da invoice não existe
        showOnChange: false,
        description: 'Nenhuma ação ocorreu com este cliente',
        color: 'info',
        logisticCheck: true,
      },
    ],
    excludedReportStatus: ['A', 'E', 'C', 'B'],
    filters: Object.assign({}, defaultCargosFiltersState()), // filtros para carga
  }
}

export default getDefaultCargosState()
export { getDefaultCargosState }
export { defaultCargosFiltersState }
