<template>
  <div class="users-list users-list-checkers">
    <list-users :users="allCheckers" type="checkers" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ListUsers from '@/components/Admin/Users/components/list'

export default {
  name: 'CheckersList',
  components: {
    ListUsers,
  },
  computed: {
    ...mapState('Checkers', ['allCheckers']),
  },
}
</script>

<style scoped>
.admins {
  width: 100%;
}
</style>
