import moment from 'moment'
import { store } from '@/store'
import format from '@/helpers/format'
import { toInteger,get } from 'lodash'

// Função utilizada em algumas rotinas dos componentes de cargo para agregação
import cargosDataTable from '@/components/Admin/Cargos/components/_cargoDataTablePrepare'
// return cargosDataTable(this.allCargos)
// return cargosDataTable(this.allCargos, this.cargoid)[0]

// Função utilizada em algumas rotinas dos componentes de cargo para agregação
// import detailsTablePrepare from '@/components/Admin/Cargos/components/_customerDetailsTablePrepare'
// return detailsTablePrepare(this.allCargos, this.cargoid, this.branchid)
// return detailsTablePrepare(this.allCargos, this.cargoid, this.branchid, this.events).details

export default {
  getAllCargosForReport:
    (state, getters, rootState, rootGetters) =>
    () => {
      let returnObj = []
      const newDataFiltered = getters.getAllCargosFiltered.filter((item) => {
        return true
      })

      newDataFiltered.forEach(async (cargo) => {
        const dataSomated = await cargosDataTable(newDataFiltered, cargo.cargo_id)[0]

        let events = await rootGetters['Events/getEventsOfCargoGetter']({
          cargoId: cargo.cargo_id,
          branchId: cargo.branch_id,
        })

        let truck = rootGetters['Trucks/getTruck'](cargo.truck_id)

        let toPush = {
          branch_id: cargo.branch_id,
          branch_name: cargo.branch_id ? rootGetters['User/getCompanieBranchName'](cargo.branch_id) : '',
          driver_name: cargo.started_by ? rootGetters['User/getDriverName'](cargo.started_by) : '',
          checker_name: cargo.checker ? cargo.checker : '',
          truck_id: cargo.truck_id ? cargo.truck_id : '',
          truck_model: truck.truck_model ? truck.truck_model : '',
          truck_cap_vol: truck.cap_volumes ? truck.cap_volumes : 0,
          truck_cap_weight: truck.cap_weight ? truck.cap_weight : 0,
          truck_cost_security: truck.cost_security ? truck.cost_security : 0,
          truck_cost_ipva: truck.cost_ipva ? truck.cost_ipva : 0,
          truck_cost_tracker: truck.cost_tracker ? truck.cost_tracker : 0,
          truck_cost_depreciation: truck.cost_depreciation ? truck.cost_depreciation : 0,
          truck_cost_others: truck.cost_others ? truck.cost_others : 0,
          truck_cost_othersdescriptions: truck.cost_othersdescriptions ? truck.cost_othersdescriptions : '',
          truck_cost_total: truck.cost_total ? truck.cost_total : 0,
          truck_started_km: cargo.started_km ? cargo.started_km : '',
          truck_finished_km: cargo.finished_km ? cargo.finished_km : '',
          truck_traveled_km: cargo.finished_km ? Math.round((cargo.finished_km - cargo.started_km)) : 0,
          cargo_id: cargo.cargo_id,
          cargo_status_id: cargo.cargo_status,
          cargo_status: cargo.cargo_status ? rootGetters['Cargos/getCargoStatusLabel'](cargo.cargo_status) : '',
          cargo_created_at: cargo.created_at ? moment.unix(cargo.created_at.seconds).format('DD/MM/YYYY HH:mm') : '',
          cargo_started_at: cargo.started_at ? moment.unix(cargo.started_at.seconds).format('DD/MM/YYYY HH:mm') : '',
          cargo_finished_at: cargo.finished_at ? moment.unix(cargo.finished_at.seconds).format('DD/MM/YYYY HH:mm') : '',
          cargo_traveled_time_s: cargo.finished_at ? Math.round((get(cargo, 'finished_at.seconds', 0) - get(cargo, 'started_at.seconds', 0))) : 0,
          cargo_duration: dataSomated.duration,
          cargo_created_by: cargo.created_by ? cargo.created_by.split(' ')[0] : '',
          cargo_started_by: cargo.started_by ? rootGetters['User/getDriverName'](cargo.started_by) : '',
          cargo_finished_by: cargo.finished_by ? rootGetters['User/getDriverName'](cargo.finished_by) : '',
          cargo_percent_finished: dataSomated.porcentFinished,

          cargo_events: events.length,
          cargo_events_ammount: events.total_value ? format(events.total_value, 'C') : format(0, 'C'),

          total_customers: dataSomated.customers,
          total_delivered_customers: dataSomated.deliveredCustomers,
          total_canceled_customers: dataSomated.canceledCustomers,
          total_reavailable_customers: dataSomated.totalReavailable,
          total_invoices: dataSomated.invoices,
          total_invoices_partial: dataSomated.invoicesPartial,
          total_invoices_canceled: dataSomated.invoicesCanceled,
          total_volumes: dataSomated.totalVolumes,
          total_amount: dataSomated.amount,
          average_ticket: dataSomated.averageTicket,
          total_weight: dataSomated.weight,
          total_checked_by_fin: dataSomated.totalCheckedByFin,
          total_checked_by_log: dataSomated.totalCheckedByLog,
          total_payment_edited: dataSomated.totalInvoicesPaymentEdited,
        }

        toPush['truck_traveled_avg'] = Math.round((toPush.truck_traveled_km / toPush.total_customers))
        toPush['cargo_traveled_time_avg_s'] = Math.round((toPush.cargo_traveled_time_s / toPush.total_customers))

        returnObj.push(toPush)
      })

      return returnObj
    },
}
