import { getDefaultCargosState, defaultCargosFiltersState } from './state'

export default {
  resetState(state) {
    // console.log(`Cargos state reseted`)
    Object.assign(state, getDefaultCargosState())
  },
  changeAllCargos(state, payload) {
    state.allCargos = [];
    state.allCargos = payload
  },
  updateCargoKm(state, payload) {
    let index = state.allCargos.findIndex(
      (cargo) => cargo.id === payload.cargoid
    )
    if (index >= 0) {
      state.allCargos[index][payload.field] = payload.newKm
      state.allCargos[index][`${payload.field}_whyUpdate`] = payload.why
    }
  },
  updateStatus(state, payload) {
    let index = state.allCargos.findIndex(
      (cargo) => cargo.id === payload.firebase_doc_id
    )
    if (index >= 0) {
      state.allCargos[index]['cargo_status'] = payload.new_status
      state.allCargos[index]['updated_at'] = payload.updated_at
      state.allCargos[index]['updated_by'] = payload.updated_by

      let history = {
        updated_at: payload.timestamp,
        updated_message: `Status alterado de ${payload.old_status} para ${payload.new_status}: ${payload.update_message}`,
        updated_field: 'cargo_status',
        updated_by: payload.updated_by,
      }

      if (Array.isArray(state.allCargos[index]['updated_history']))
        state.allCargos[index]['updated_history'].push(history)
      else state.allCargos[index]['updated_history'] = [history]
    }
  },
  setSearchCargosFilter(state, payload) {
    if (payload) state.filters = payload
    else state.filters = defaultCargosFiltersState()
  },
  resetSearchCargosFilter(state) {
    state.filters = defaultCargosFiltersState()
  },
  updateHelpers(state, payload) {
    if (!payload.firebaseDocId) return false

    let index = state.allCargos.findIndex(
      (cargo) => cargo.id === payload.firebaseDocId
    )
    if (index >= 0) {
      state.allCargos[index]['updated_at'] = payload.updated_at
      state.allCargos[index]['updated_by'] = payload.updated_by
      state.allCargos[index]['helpers_ids'] = payload.helpers_ids
      state.allCargos[index]['helpers_names'] = payload.helpers_names
      state.allCargos[index]['helpers_update_message'] = payload.helpers_update_message
    }
  },
  updateCheckers(state, payload) {
    if (!payload.firebaseDocId) return false

    let index = state.allCargos.findIndex(
      (cargo) => cargo.id === payload.firebaseDocId
    )
    if (index >= 0) {
      state.allCargos[index]['updated_at'] = payload.updated_at
      state.allCargos[index]['updated_by'] = payload.updated_by
      state.allCargos[index]['checkers_ids'] = payload.checkers_ids
      state.allCargos[index]['checkers_names'] = payload.checkers_names
      state.allCargos[index]['checkers_update_message'] = payload.checkers_update_message
      state.allCargos[index]['checker'] = payload.checker
    }
  },
}
