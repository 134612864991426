export default {
  getCargoStatusLabel: (state) => (cargo_status) => {
    // Retorna nome ou o próprio valor do cargo_status
    let labelState = state.statusLabels.find((item) => item.content === cargo_status) // retorna objeto ou undefined
    let label = labelState
      ? labelState.value
      : cargo_status
    return label
  },
  getCargoDeliveryLabels: (state) => (status, d = {}, options = {}) => {
    // Retorna nome ou o próprio valor do cargo_status
    // Recebe ou o objeto customer ou o objeto invoice da carga na variavel d
    // Recebe opções como alterador de configurações de processamento
    //     como returnObject: true para retornar todo o labelState em vez de apenas o valor

    let labelState = {}
    labelState = state.deliveryLabels.find((item) => item.content === status)

    if (d.invoice) {
      if (d.has_invoice_partial)                              labelState = state.deliveryLabels.find((item) => item.content === 'with_partial')
    }

    if (d.customer) {
      if (!d.status)                                         labelState = state.deliveryLabels.find((item) => item.content === 'not_started')
      if (d.status == 'delivered' && d.has_invoice_partial)  labelState = state.deliveryLabels.find((item) => item.content === 'with_partial')
      if (d.status == 'delivered' && d.has_invoice_canceled) labelState = state.deliveryLabels.find((item) => item.content === 'with_one_invoice_canceled')
      if (d.status == 'delivered' && d.reavailable_at)       labelState = state.deliveryLabels.find((item) => item.content === 'reavailable')
      if (d.status == 'delivered' && d.reavailable_at && d.has_invoice_partial)       labelState = state.deliveryLabels.find((item) => item.content === 'reavailable_with_partial')
    }

    if (options.returnObject) {
      return labelState
    } else {
      let label = labelState
        ? labelState.value
        : status
      return label
    }
  },
}
