<template>
  <div class="users-list users-list-admins">
    <list-users :users="allAdmins" type="admins" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ListUsers from '@/components/Admin/Users/components/list'

export default {
  name: 'AdminsList',
  components: {
    ListUsers,
  },
  computed: {
    ...mapState('Admins', ['allAdmins'])
  },
}
</script>

<style scoped>
.admins {
  width: 100%;
}
</style>
