<template>
  <div class="customer-tags" v-if="customer" :style="style">
    <el-tag
      :type="customer.statusColor"
      disable-transitions
      size="mini"
      class="tag"
      >{{ customer.statusLabel }}</el-tag
    >

    <el-tag
      v-if="
        customer.checked_fin &&
        (customer.checked_log || !customer.needLogisticCheck)
      "
      type="success"
      disable-transitions
      size="mini"
      class="tag"
      >Conferido</el-tag
    >

    <el-tag
      v-else-if="customer.checked_fin || customer.checked_log"
      type="info"
      disable-transitions
      size="mini"
      class="tag"
      >Conferido parcial</el-tag
    >

    <el-tag v-else type="info" disable-transitions size="mini" class="tag"
      >Não conferido</el-tag
    >

    <el-tag
      v-if="customer.invoicesHasPaymentEdited"
      :type="typeColor(customer, 'danger')"
      disable-transitions
      size="mini"
      class="tag"
      >Pagamento alterado</el-tag
    >
  </div>
</template>

<script>
export default {
  name: 'cargos-tags',
  props: {
    customer: {
      type: Object,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: 'column',
    },
  },
  data() {
    return {}
  },
  computed: {
    style() {
      return `flex-direction: ${this.direction};`
    },
  },
  methods: {
    typeColor(customer, defaultColor = 'info') {
      if (customer.checked_fin && customer.checked_log) return 'success'
      else return defaultColor
    },
  },
  mounted() {},
}
</script>

<style scoped>
.customer-tags {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.tag {
  float: right;
  width: 125px;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 6px;
}
</style>
