<template>
  <el-dialog
    :title="title"
    :visible.sync="showThisDialog"
    center
    :before-close="onClose"
  >
    <el-form :model="form" ref="formCargoHelpers" label-position="top">

      <el-form-item label="Selecione um ou mais ajudantes" prop="helpers_ids">
        <el-row type="flex" justify="center" :gutter="1">
          <el-col :span="19">
          <el-select v-model="form.helpers_ids" placeholder="Ajudantes" multiple collapse-tags :multiple-limit=3>
            <el-option v-for="user in allDriverHelpers" :key="user.auth_uid" :label="user.name" :value="user.auth_uid">
              <span style="float: left">{{ user.name }}</span>
            </el-option>
          </el-select>
          </el-col>
          <el-col :span="5">
            <el-button plain icon="el-icon-refresh" @click="getDriverHelpers" style="margin:0;"/>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="Motivo da alteração" prop="updateMessage">
        <el-input v-model="form.updateMessage" placeholder="Informe uma breve descrição para registro desta alteração"/>
      </el-form-item>

    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="submitForm()"
        :loading="isLoading"
        style="width: 140px;"
        >
        Salvar
      </el-button>
      <el-button @click="onClose" :disabled="isLoading">Cancelar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'edit-cargo-helpers-dialog',
  props: {},
  data() {
    return {
      showThisDialog: false,
      title: 'Alterar ajudantes da carga',
      submitDisabled: true,
      isLoading: false,
      form: (this.getForm())
    }
  },
  computed: {
    ...mapState('DriverHelpers', ['allDriverHelpers']),
    ...mapGetters('DriverHelpers', ['getDriverHelperNamesByAuthIds']),
  },
  methods: {
    ...mapActions('Cargos', ['updateHelper']),
    ...mapActions('DriverHelpers', ['getDriverHelpers']),
    ...mapMutations('Shared', ['setError', 'setSuccessMessage']),
    async submitForm() {
            this.isLoading = true
            try {
              let success = await this.updateHelper(this.form)
              if (success) {
                this.resetForm()
                this.showThisDialog = false
                this.setSuccessMessage('Ajudante alterado com sucesso.')
              }
            } catch (error) {
              this.setError('Ocorreu alguma falha na alteração do ajudante.')
            }

            this.isLoading = false
    },
    onClose() {
      // Run when the dialods close
      this.resetForm()
      this.showThisDialog = false
    },
    getForm() {
      return {
        helpers_ids: [],
        helpers_names: [],
        updateMessage: '',
        branch_id: '',
        cargo_id: '',
      }
    },
    resetForm() {
      this.form = this.getForm()
    },
    showDialog(cargo) {
      if (cargo.helpers_ids) {
        this.form.helpers_ids = cargo.helpers_ids
      }

      if (cargo.branch_id && cargo.cargo_id) {
        this.form.branch_id = cargo.branch_id
        this.form.cargo_id = cargo.cargo_id
        this.showThisDialog = true
      } else {
        this.setError('Não foi possível identificar a carga.')
        this.resetForm()
      }
    },
  },
  watch: {
    'form.helpers_ids': function (value) {
      this.form.helpers_names = this.getDriverHelperNamesByAuthIds(value)
    }
  },
}
</script>
