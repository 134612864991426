export default {
  selectedTruckSavedKm(state) {
    if (state.selectedTruck.km) return state.selectedTruck.km
    else return false
  },
  getTruckLastLocation: (state) => (id) => {
    // console.log(state.allTrucks.find((truck) => truck.id === id))
    const truck = state.allTrucks.find((truck) => truck.id === id)
    const location = truck.location || {}
    if (truck && truck.location) location.updated_at = truck.updated_at_location
    return location
  },
  getTruckBranch: (state) => (id) => {
    const truck = state.allTrucks.find((truck) => truck.id === id)
    const branch = truck.branch_id || null
    return branch
  },
  getTruck: (state, getters) => (id) => {
    const truck = state.allTrucks.find((truck) => truck.id === id)
    return {
      branch_id: truck.branch_id || '',
      cap_volumes: truck.cap_volumes || 0,
      cap_weight: truck.cap_weight || 0,
      truck_id: truck.truck_id || '',
      truck_model: truck.truck_model || '',
      location: truck.location || null,
      location_updated: truck.updated_at_location || null,
      km: truck.km || 0,
      last_user_id: truck.last_user_id || null,
      cost_security: truck.cost_security || 0,
      cost_ipva: truck.cost_ipva || 0,
      cost_tracker: truck.cost_tracker || 0,
      cost_depreciation: truck.cost_depreciation || 0,
      cost_others: truck.cost_others || 0,
      cost_othersdescriptions: truck.cost_othersdescriptions || '',
      cost_total: getters.getTruckCost(truck.id, truck),
      is_disabled: truck.is_disabled || false,
      has_special_compartment: truck.has_special_compartment || false,
      special_compartment: truck.special_compartment || '',
      created_at: truck.created_at || null,
      created_by: truck.created_by || null,
      updated_at: truck.updated_at || null,
      updated_by: truck.updated_by || null
    }
  },
  getTruckCost: (state) => (id, truck = null) => {
    if (!truck) truck = state.allTrucks.find((truck) => truck.id === id)
    let cost = 0
    cost = (cost + (truck.cost_security || 0))
    cost = (cost + (truck.cost_ipva || 0))
    cost = (cost + (truck.cost_tracker || 0))
    cost = (cost + (truck.cost_depreciation || 0))
    cost = (cost + (truck.cost_others || 0))
    return cost
  },
}
