export default {
  getDriverCheckerNamesByAuthIds: (state, getters, rootState) => (authIds) => {
    if (Array.isArray(state.allCheckers) && state.allCheckers.length > 0 && Array.isArray(authIds)) {
      return state.allCheckers
        .filter(checker => authIds.includes(checker.auth_uid))
        .map(checker => checker.name)
    }
    return []
  }
}
