<template>
  <el-dialog
    :title="title"
    :visible.sync="showThisDialog"
    center
    :before-close="onClose"
  >
    <el-form :model="form" ref="formCargoCheckers" label-position="top">

      <el-form-item label="Selecione um ou mais conferentes" prop="checkers_ids">
        <el-row type="flex" justify="center" :gutter="1">
          <el-col :span="19">
          <el-select v-model="form.checkers_ids" placeholder="Conferentes" multiple collapse-tags :multiple-limit=3>
            <el-option v-for="user in allCheckers" :key="user.auth_uid" :label="user.name" :value="user.auth_uid">
              <span style="float: left">{{ user.name }}</span>
            </el-option>
          </el-select>
          </el-col>
          <el-col :span="5">
            <el-button plain icon="el-icon-refresh" @click="getCheckers" style="margin:0;"/>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="Motivo da alteração" prop="updateMessage">
        <el-input v-model="form.updateMessage" placeholder="Informe uma breve descrição para registro desta alteração"/>
      </el-form-item>

    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="submitForm()"
        :loading="isLoading"
        style="width: 140px;"
        >
        Salvar
      </el-button>
      <el-button @click="onClose" :disabled="isLoading">Cancelar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'edit-cargo-checkers-dialog',
  props: {},
  data() {
    return {
      showThisDialog: false,
      title: 'Alterar conferentes da carga',
      submitDisabled: true,
      isLoading: false,
      form: (this.getForm())
    }
  },
  computed: {
    ...mapState('Checkers', ['allCheckers']),
    ...mapGetters('Checkers', ['getDriverCheckerNamesByAuthIds']),
  },
  methods: {
    ...mapActions('Cargos', ['updateChecker']),
    ...mapActions('Checkers', ['getCheckers']),
    ...mapMutations('Shared', ['setError', 'setSuccessMessage']),
    async submitForm() {
            this.isLoading = true
            try {
              let success = await this.updateChecker(this.form)
              if (success) {
                this.resetForm()
                this.showThisDialog = false
                this.setSuccessMessage('Conferente alterado com sucesso.')
              }
            } catch (error) {
              this.setError('Ocorreu alguma falha na alteração do conferente.')
            }

            this.isLoading = false
    },
    onClose() {
      // Run when the dialods close
      this.resetForm()
      this.showThisDialog = false
    },
    getForm() {
      return {
        checkers_ids: [],
        checkers_names: [],
        checker: '',
        updateMessage: '',
        branch_id: '',
        cargo_id: '',
      }
    },
    resetForm() {
      this.form = this.getForm()
    },
    showDialog(cargo) {
      if (cargo.checkers_ids) {
        this.form.checkers_ids = cargo.checkers_ids
      }

      if (cargo.branch_id && cargo.cargo_id) {
        this.form.branch_id = cargo.branch_id
        this.form.cargo_id = cargo.cargo_id
        this.showThisDialog = true
      } else {
        this.setError('Não foi possível identificar a carga.')
        this.resetForm()
      }
    },
  },
  watch: {
    'form.checkers_ids': function (value) {
      this.form.checkers_names = this.getDriverCheckerNamesByAuthIds(value)
      this.form.checker = (this.getDriverCheckerNamesByAuthIds(value)).join(', ')
    }
  },
}
</script>
