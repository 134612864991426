import { getDefaultDriverHelpersState } from './state'

export default {
  resetState(state) {
    // console.log(`DriverHelper state reseted`)
    Object.assign(state, getDefaultDriverHelpersState())
  },
  changeDriverHelpers(state, payload) {
    state.allDriverHelpers = payload
  },
  addDriverHelper(state, payload) {
    state.allDriverHelpers.push(payload)
  },
  removeDriverHelper(state, payload) {
    state.allDriverHelpers = state.allDriverHelpers.filter(
      (driverhelper) => driverhelper.driver_login !== payload
    )
  },
  updateDriverHelper(state, payload) {
    const driverhelperIndex = state.allDriverHelpers.findIndex(
      (driverhelper) => driverhelper.driver_login === payload.driver_login
    )
    if (driverhelperIndex >= 0) {
      Object.assign(state.allDriverHelpers[driverhelperIndex], payload)
    } else {
      state.allDriverHelpers.push(payload)
    }
  },
}
