<template>
  <div class="documents-settings">
    <global-title-with-filter-bar
      :title="$route.meta.title"
      :hidedate="isAddInvoice"
      @gFilterSearchClick="updateInvoiceList"
      :buttons="buttons"
    />
    <router-view></router-view>

    <find-invoice-dialog ref="findInvoiceDialog" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'
import findInvoiceDialog from './components/findInvoiceDialog'

export default {
  name: 'invoices',
  components: {
    globalTitleWithFilterBar,
    findInvoiceDialog,
  },
  computed: {
    isAddInvoice() {
      return this.$route.name === 'InvoicesAdd'
    },
    buttons() {
      const that = this
      if (this.isAddInvoice) {
        return []
      } else {
        return [
          {
            text: 'Adicionar Documento',
            click: () => that.$router.push({ name: 'InvoicesAdd' }),
            type: 'success',
          },
        ]
      }
    },
  },
  mounted() {
    this.updateInvoiceList()
  },
  methods: {
    ...mapActions('Invoices', ['getAndMonitorInvoices']),
    async updateInvoiceList() {
      await this.getAndMonitorInvoices({
        force: true,
        filterStatus: 'A',
        useDateFilters: true,
      }).then((invoices) => {})
    },
  },
}
</script>
